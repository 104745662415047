import React from "react";
import AwesomeSlider from 'react-awesome-slider';
import CoreStyles from 'react-awesome-slider/src/core/styles.scss';
import AnimationStyles from 'react-awesome-slider/src/styled/fold-out-animation/fold-out-animation.scss';
import storefront_1 from "../assets/examples/storefront/store_front_1.png";
import storefront_2 from "../assets/examples/storefront/store_front_2.png";
import storefront_3 from "../assets/examples/storefront/store_front_3.png";
import storefront_4 from "../assets/examples/storefront/store_front_4.png";
import storefront_5 from "../assets/examples/storefront/store_front_5.png";
import storefront_6 from "../assets/examples/storefront/store_front_6.png";
import storefront_7 from "../assets/examples/storefront/store_front_7.png";
import storefront_8 from "../assets/examples/storefront/store_front_8.png";
import storefront_9 from "../assets/examples/storefront/store_front_9.png";
import storefront_10 from "../assets/examples/storefront/store_front_10.png";
import storefront_11 from "../assets/examples/storefront/store_front_11.png";
import storefront_12 from "../assets/examples/storefront/store_front_12.png";
import sticker_1 from "../assets/examples/stickers/sticker_1.png";
import sticker_2 from "../assets/examples/stickers/sticker_2.png";
import sticker_3 from "../assets/examples/stickers/sticker_3.png";
import sticker_4 from "../assets/examples/stickers/sticker_4.png";
import sticker_5 from "../assets/examples/stickers/sticker_5.png";
import apartment_1 from "../assets/examples/apartments/apartments_1.png";
import apartment_2 from "../assets/examples/apartments/apartments_2.png";
import apartment_3 from "../assets/examples/apartments/apartments_3.png";
import trailer_1 from "../assets/examples/trailer/trailer_1.png";
import trailer_2 from "../assets/examples/trailer/trailer_2.png";
import banner_1 from "../assets/examples/banner/banner_1.png";
import banner_2 from "../assets/examples/banner/banner_2.png";
import banner_3 from "../assets/examples/banner/banner_3.png";
import banner_4 from "../assets/examples/banner/banner_4.png";
import htv_1 from "../assets/examples/htv/htv_1.png";
import htv_2 from "../assets/examples/htv/htv_2.png";
import htv_3 from "../assets/examples/htv/htv_3.png";
import htv_4 from "../assets/examples/htv/htv_4.png";
import htv_5 from "../assets/examples/htv/htv_5.png";
import htv_6 from "../assets/examples/htv/htv_6.png";
import htv_7 from "../assets/examples/htv/htv_7.png";
import interactive_1 from "../assets/examples/interactive/interactive_1.png";
import interactive_2 from "../assets/examples/interactive/interactive_2.png";
import interactive_3 from "../assets/examples/interactive/interactive_3.png";
import interactive_4 from "../assets/examples/interactive/interactive_4.png";
import interactive_5 from "../assets/examples/interactive/interactive_5.png";
import interactive_6 from "../assets/examples/interactive/interactive_6.png";
import interactive_7 from "../assets/examples/interactive/interactive_7.png";
import interactive_8 from "../assets/examples/interactive/interactive_8.png";
import food_truck_1 from "../assets/examples/food_truck/food_truck_1.png";
import food_truck_2 from "../assets/examples/food_truck/food_truck_2.png";
import food_truck_3 from "../assets/examples/food_truck/food_truck_3.png";
import food_truck_4 from "../assets/examples/food_truck/food_truck_4.png";
import aluminum_1 from "../assets/examples/aluminum/aluminum_1.png";
import aluminum_2 from "../assets/examples/aluminum/aluminum_2.png";

import Container from "@material-ui/core/Container";
import {Dialog, DialogContent, DialogContentText, useMediaQuery} from "@mui/material";
import {useTranslation} from "react-i18next";
import {useDarkMode} from "../util/theme";

function ExampleDialog({examplesToLoad, dialogIsOpen}) {
    const { t } = useTranslation();
    const isDark = useDarkMode().value;
    const isMobile = useMediaQuery("(max-width:600px)");


    // Updated color tones for Charcoal / Light Charcoal
    const darkBgColor = "#333333"; // Charcoal
    const lightBgColor = "#E0E0E0"; // Light Charcoal
    const textColor = isDark ? "#F5F5F5" : "#2D2D2D"; // Light for dark mode, dark for light mode


    const photoExamples =
        {
            store_front: [
                {urlPath: storefront_1, alt: "Monster World Vertical", id: 1},
                {urlPath: storefront_2, alt: "Monster World Vertical 2", id: 2},
                {urlPath: storefront_3, alt: "Earth Illuminated 1", id: 3},
                {urlPath: storefront_4, alt: "Earth Illuminated 1", id: 4},
                {urlPath: storefront_5, alt: "Black Sheep", id: 5},
                {urlPath: storefront_6, alt: "The Tallent Company", id: 6},
                {urlPath: storefront_7, alt: "Dominics", id: 7},
                {urlPath: storefront_8, alt: "Johnstown Breeze", id: 8},
                {urlPath: storefront_9, alt: "Ice Rink", id: 9},
                {urlPath: storefront_10, alt: "Ice Rink 2", id: 10},
                {urlPath: storefront_11, alt: "Wellness Spring", id: 11},
                {urlPath: storefront_12, alt: "Monster World", id: 12},
            ],
            sticker: [
                {urlPath: sticker_1, alt: "Custom Sticker 1", id: 1},
                {urlPath: sticker_2, alt: "Custom Sticker 2", id: 2},
                {urlPath: sticker_3, alt: "Custom Sticker 3", id: 3},
                {urlPath: sticker_4, alt: "Custom Sticker 4", id: 4},
                {urlPath: sticker_5, alt: "Custom Sticker 5", id: 5},
            ],
            banner: [
                {urlPath: banner_1, alt: "Banner 1", id: 1},
                {urlPath: banner_2, alt: "Banner 2", id: 2},
                {urlPath: banner_3, alt: "Banner 3", id: 3},
                {urlPath: banner_4, alt: "Banner 4", id: 4},
            ],
            trailer: [
                {urlPath: trailer_1, alt: "Trailer 1", id: 1},
                {urlPath: trailer_2, alt: "Trailer 2", id: 2},
            ],
            htv: [
                {urlPath: htv_1, alt: "HTV 1", id: 1},
                {urlPath: htv_2, alt: "HTV 2", id: 2},
                {urlPath: htv_3, alt: "HTV 3", id: 3},
                {urlPath: htv_4, alt: "HTV 4", id: 4},
                {urlPath: htv_5, alt: "HTV 5", id: 5},
                {urlPath: htv_6, alt: "HTV 6", id: 6},
                {urlPath: htv_7, alt: "HTV 7", id: 7},
            ],
            interactive_art: [
                {urlPath: interactive_1, alt: "Banner 1", id: 1},
                {urlPath: interactive_2, alt: "Banner 2", id: 2},
                {urlPath: interactive_3, alt: "Banner 3", id: 3},
                {urlPath: interactive_4, alt: "Banner 4", id: 4},
                {urlPath: interactive_5, alt: "Banner 5", id: 5},
                {urlPath: interactive_6, alt: "Banner 6", id: 6},
                {urlPath: interactive_7, alt: "Banner 7", id: 7},
                {urlPath: interactive_8, alt: "Banner 8", id: 8},
            ],
            food_truck: [
                {urlPath: food_truck_1, alt: "Food Truck 1", id: 1},
                {urlPath: food_truck_2, alt: "Food Truck 2", id: 2},
                {urlPath: food_truck_3, alt: "Food Truck 3", id: 3},
                {urlPath: food_truck_4, alt: "Food Truck 4", id: 4},
            ],
            apartments: [
                {urlPath: apartment_3, alt: "Apartment 3", id: 3},
                {urlPath: apartment_1, alt: "Apartment 1", id: 1},
                {urlPath: apartment_2, alt: "Apartment 2", id: 2},
            ],
            metal: [
                {urlPath: aluminum_1, alt: "Aluminum 1", id: 1},
                {urlPath: aluminum_2, alt: "Aluminum 1", id: 2},
            ]
        };

    const handleClose = () => {
        dialogIsOpen(false);
    };
    return (
        <Container sx={{ display: dialogIsOpen ? "block" : "none", zIndex: 10 , marginTop: "5px"}}>
            <Dialog
                fullWidth
                maxWidth="md"
                open={dialogIsOpen}
                onClose={handleClose}
                sx={{
                    "& .MuiDialog-paper": {
                        backgroundColor: isDark ? darkBgColor : lightBgColor,
                        boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.5)",
                        borderRadius: "12px",
                        padding: 2,
                    },
                }}
            >
                <DialogContent sx={{ textAlign: "center", padding: isMobile ? "10px" : "10px" }}>
                    <DialogContentText
                        sx={{
                            backgroundColor: isDark ? darkBgColor : lightBgColor,
                            fontSize:  isMobile ? "1.8rem":"2.3rem",
                            fontWeight: 700,
                            color: textColor,
                            marginBottom: "10px",
                        }}
                    >
                        {t(`services.list.${examplesToLoad}.title`)}
                    </DialogContentText>

                    <AwesomeSlider bullets={false} animation="foldOutAnimation" cssModule={[CoreStyles, AnimationStyles]}>
                        {photoExamples[examplesToLoad]?.map((example) => (
                            <div key={example.id} data-src={example.urlPath} alt={example.alt} />
                        ))}
                    </AwesomeSlider>

                    <DialogContentText sx={{ marginTop: 3, fontSize: "16px", color: textColor }}>
                        {t(`services.list.${examplesToLoad}.description`)}
                    </DialogContentText>
                </DialogContent>
            </Dialog>
        </Container>
    );
}

export default ExampleDialog;