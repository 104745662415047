import React from "react";
import { Grid, Card, CardMedia, CardContent, Typography, Container } from "@mui/material";
import etsyListings from "../assets/etsy_listings.json";

const EtsyShop = () => {
    return (
        <Container maxWidth="lg" sx={{ py: 6 }}>
            <Typography variant="h4" component="h1" align="center" gutterBottom>
                {/*eslint-disable-next-line*/}
                Listings From Our <a href="https://vaporsigns.etsy.com/" target="_blank" style={{color: '#eb6d20', fontWeight: 'bold', textDecoration: 'none'}}>Etsy</a> Shop
            </Typography>
            <Typography variant="body1" align="center" paragraph>
                Check out our latest products available on Etsy! Click on any item to view it on Etsy.
            </Typography>
            <Grid container spacing={4} justifyContent="center" marginTop={3} >
                {etsyListings.map((item) => (
                    <Grid item key={item.id} xs={12} sm={6} md={4} lg={3}>
                        <Card sx={{ borderRadius: 3, boxShadow: 3, transition: "0.3s", '&:hover': { boxShadow: 6 } }}>
                            <a href={item.url} target="_blank" rel="noopener noreferrer" style={{ textDecoration: "none", color: "inherit" }}>
                                <CardMedia component="img" height="200" image={item.image} alt={item.title} sx={{ borderTopLeftRadius: 3, borderTopRightRadius: 3 }} />
                                <CardContent>
                                    <Typography variant="h6" component="h3" align="center" sx={{ fontWeight: "bold" }}>
                                        {item.title}
                                    </Typography>
                                </CardContent>
                            </a>
                        </Card>
                    </Grid>
                ))}
            </Grid>
        </Container>
    );
};

export default EtsyShop;
