import React from "react";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Box from "@material-ui/core/Box";
import Avatar from "@material-ui/core/Avatar";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Section from "./Section";
import SectionHeader from "./SectionHeader";
import {useTranslation} from "react-i18next";
import sticker from "../assets/sticker.png";
import htv from "../assets/htv.png";


const useStyles = makeStyles((theme) => ({
    card: {
        display: "flex",
        flexDirection: "column",
        height: "100%",
    },
    avatarWrapper: {
        display: "flex",
        justifyContent: "center",
    },
    avatar: {
        width: theme.spacing(15),
        height: theme.spacing(15),
    },
}));

function InstructionCard(props) {
    const classes = useStyles();
    const { t } = useTranslation();

    const items = [
        {
            avatar: sticker,
            name: t('instructions.item.sticker.name'),
            role:  t('instructions.item.sticker.description'),
            bio:  ''
        },
        {
            avatar: htv,
            name: t('instructions.item.htv.name'),
            role:  t('instructions.item.htv.description'),
            bio:  ''
        }
    ];

    return (
        <Section
            bgColor={props.bgColor}
            size={props.size}
            bgImage={props.bgImage}
            bgImageOpacity={props.bgImageOpacity}
        >
            <Container>
                <SectionHeader
                    title={props.title}
                    subtitle={props.subtitle}
                    size={4}
                    textAlign="center"
                />
                <Grid container={true} justifyContent="center" spacing={4}>
                    {items.map((item, index) => (
                        <Grid item={true} xs={12} sm={6} md={4} key={index}>
                            <Card className={classes.card}>
                                <CardContent>
                                    <Box className={classes.avatarWrapper}>
                                        <Avatar
                                            src={item.avatar}
                                            alt={item.name}
                                            className={classes.avatar}
                                        />
                                    </Box>
                                    <Box textAlign="center" pt={3}>
                                        <Typography variant="body2" component="p">
                                            {item.name}
                                        </Typography>
                                        <Typography
                                            variant="body2"
                                            color="textSecondary"
                                            component="p"
                                        >
                                            {item.role}
                                        </Typography>
                                        <Box mt={2}>
                                            <Typography variant="body1" component="p">
                                                {item.bio}
                                            </Typography>
                                        </Box>
                                    </Box>
                                </CardContent>
                            </Card>
                        </Grid>
                    ))}
                </Grid>
            </Container>
        </Section>
    );
}

export default InstructionCard;
