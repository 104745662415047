import React from "react";
import Meta from "./../components/Meta";
import HeroSection2 from "../components/HeroSection2";
import bkgi from "../assets/bckg.png";
import InstructionCard from "../components/InstructionCard";
import {useTranslation} from "react-i18next";

function InstructionsPage(props) {
    const { t } = useTranslation()
    return (

        <>
            <Meta title={t('instructions.title')} description={t('instructions.description')} />
            <HeroSection2
                bgColor="default"
                size="large"
                bgImage={bkgi}
                bgImageOpacity={0.4}
                title={t('instructions.heading')}
                subtitle={t('instructions.subheading')}
            />
            <InstructionCard
                bgColor="default"
                size="medium"
                bgImage=""
                bgImageOpacity={1}
                title="What Are You Installing?"
                subtitle=""
            />
        </>
    );
}

export default InstructionsPage;
